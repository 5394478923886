import React from "react";
import "./HomePage.scss";
import AboutUs from "../../components/AboutUs/AboutUs";
import OurServices from "./../OurServices/OurServices";
import ProductCards from "../../components/ProductCard/ProductCards";
function HomePage() {
  return (
    <>
      <AboutUs from="homepage" />
      <hr />
      <ProductCards from="homepage" />
      <hr />
      <OurServices from="homepage" />
    </>
  );
}

export default HomePage;
