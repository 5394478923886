import React, { useEffect, useState } from "react";
import { getProducts } from "../../data/products";
import ProductCard from "./ProductCard";
import "./ProductCards.scss";

function ProductCards({ from }) {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts().then((res) => setProducts(res));
  }, []);
  return (
    <>
      <h2 className="products-title">Our Products</h2>
      <div className={`products ${from === "homepage" && "homepage"}`}>
        {products.map((product, i) => (
          <ProductCard key={i} product={product} />
        ))}
      </div>
    </>
  );
}

export default ProductCards;
