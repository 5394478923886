import React from "react";
import "./Services.scss";
import OurServicesImage from "../../data/services.png";
import Cards from "../Cards/Cards";

function Services({ from }) {
  return (
    <div className={`services__content ${from === "homepage" && "homepage"}`}>
      {from !== "homepage" && (
        <div className="services__content-left-section">
          <img src={OurServicesImage} alt="Our services" />
          <p className="services__content-left-section-text">
            Flexible production and contract manufacturing directed to the needs
            and requirements of our customers.
          </p>
        </div>
      )}
      <div className="services__content-right-section">
        <Cards from={from} />
      </div>
    </div>
  );
}

export default Services;
