export const imageToBase64 = (file) => {
  return new Promise((resolve) => {
    let baseUrl = "";

    fetch(file)
      .then((response) => response.blob())
      .then((blob) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = (e) => {
          baseUrl = e.target.result;
          resolve(baseUrl)
        };
      });
  });
};
