import { imageToBase64 } from "../helpers/imageToBase64";
import FlangeImage from "./flanges.png";
import HammerUnionImage from "./hammerunion.png";
import CrossOverImage from "./crossovers.png";
import StudsImage from "./studs.png";
import RingGasketsImage from "./ring-gasket.png";
import CNCImage from "./cnc.jpg";
import ValveImage from "./valves.jpg";
import MetalCuttingImage from "./metal-cutting.jpg";
import ForgingImage from "./forging.jpg";
import GateValveImage from "./gatevalve.png"

export async function getProducts() {
  const flangePromise = imageToBase64(FlangeImage);
  const hammerUnionPromise = imageToBase64(HammerUnionImage);
  const crossOverPromise = imageToBase64(CrossOverImage);
  const studsPromise = imageToBase64(StudsImage);
  const ringGasketsPromise = imageToBase64(RingGasketsImage);
  const cncPromise = imageToBase64(CNCImage);
  const valvePromise = imageToBase64(ValveImage);
  const metalCuttingPromise = imageToBase64(MetalCuttingImage);
  const forgingPromise = imageToBase64(ForgingImage);
  const gateValvePromise = imageToBase64(GateValveImage)

  const [
    flangeImage,
    hammerUnionImage,
    crossOverImage,
    studsImage,
    ringGasketsImage,
    cncImage,
    valveImage,
    metalCuttingImage,
    forgingImage,
    gateValveImage
  ] = await Promise.all([
    flangePromise,
    hammerUnionPromise,
    crossOverPromise,
    studsPromise,
    ringGasketsPromise,
    cncPromise,
    valvePromise,
    metalCuttingPromise,
    forgingPromise,
    gateValvePromise
  ]);

  return getUpdatedProducts(
    flangeImage,
    hammerUnionImage,
    crossOverImage,
    studsImage,
    ringGasketsImage,
    cncImage,
    valveImage,
    metalCuttingImage,
    forgingImage,
    gateValveImage
  );
}

function getUpdatedProducts(
  flange,
  hammerUnion,
  crossOver,
  studs,
  ringGasket,
  cnc,
  valve,
  metalCutting,
  forging,gateValve
) {
  return [
    {
      id: "flanges",
      title: "Flanges",
      link: "https://www.nmtes.in/flange-detail.php",
      imgSrc: flange,
    },
    {
      id: "couplings",
      title: "Metallic Ring Gaskets and Couplings",
      link: "https://www.oilfieldproducts.in/ring-joint-gaskets.php",
      imgSrc: ringGasket,
    },
    {
      id: "unions",
      title: "Union joints and connections (Hammer Union)",
      link: "https://www.oilfieldproducts.in/hammer-unions.php",
      imgSrc: hammerUnion,
    },
    {
      id: "threaded-pipe",
      title: "Cross over and threaded Pipe Couplings",
      link: "https://www.oilfieldproducts.in/cross-overs.php",
      imgSrc: crossOver,
    },
    {
      id: "Studs",
      title: "High Tensile Studs & Bolts",
      link: "https://www.nmtes.in/studs-bolts-detail.php",
      imgSrc: studs,
    },
    {
      id: "services",
      title: "CNC metal cutting & machining services",
      link: "https://www.oilfieldproducts.in/welding.php",
      imgSrc: metalCutting,
    },
    {
      id: "valves",
      title: "Valves: Gate Valve, Butterfly Valve & check valves",
      link: "https://www.nmtes.in/products.php",
      imgSrc: gateValve
    },
    {
      id: "spared",
      title: "Spares for valves",
      link: "https://www.oilfieldproducts.in/api-gate-valve-parts.php",
      imgSrc: valve,
    },
    {
      id: "casting",
      title: "Forging and casting components",
      link: "https://www.nmtes.in/products.php",
      imgSrc: forging,
    },
    {
      id: "equipments",
      title: "Contract manufacturing for equipments and spares",
      link: "https://www.oilfieldproducts.in/cnc-turned-parts-manufacturers.php",
      imgSrc: cnc,
    },
  ];
}
