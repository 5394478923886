import React from "react";

function ProductCard({ product: { id, title, link, imgSrc } }) {
  return (
    <a className="products-card" href={link} target="_blank" rel="noreferrer">
      <img src={imgSrc} alt={id} />
      <div className="products-card__title">{title}</div>
    </a>
  );
}

export default ProductCard;
