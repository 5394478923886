import React, { useEffect, useState } from "react";
import "./AboutUs.scss";
import { paragraphs } from "../../data/paragraphs";
import AboutUsImage from "../../data/about-us.jpg";

function AboutUs({ from }) {
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentParagraphIndex < paragraphs.length - 1) {
        setCurrentParagraphIndex((prevIndex) => prevIndex + 1);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [currentParagraphIndex]);
  return (
    <div className={`aboutus ${from === "homepage" && "homepage"} `}>
      {from === "homepage" && (
        <div className="aboutus__image">
          <img src={AboutUsImage} alt="About Us" />{" "}
        </div>
      )}
      <div className="aboutus__main-content">
        {from !== "homepage" && (
          <h2 className="aboutus__main-content-section">About Us</h2>
        )}
        {paragraphs.map((paragraph, index) => (
          <p key={index} className={`aboutus__main-paragraph`}>
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
}

export default AboutUs;
