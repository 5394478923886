import React from "react";
import Services from "../../components/Services/Services";

function OurServices({ from }) {
  return (
    <div className="services">
      {from === "homepage" && (
        <h2 className="services__content-title"> Our Services</h2>
      )}
      <Services from={from} />
    </div>
  );
}

export default OurServices;
