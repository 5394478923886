import React from "react";

function Card({ service: { text, img, id } }) {

  return (
    <div className="service-card">
      <div className="service-card__image">
        {img.startsWith("<svg") ? (
          <span dangerouslySetInnerHTML={{ __html: img }}></span>
        ) : (
          <img src={img} alt={id} />
        )}
      </div>
      <div className="service-card__text">{text}</div>
    </div>
  );
}

export default Card;
