import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-info">
        <ul className="footer-info__main">
          <li className="footer-info__main-title">Calgary Branch</li>
          <li className="footer-info__main-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
            </span>
            <span>212 Cornerstone Manor NE Calgary AB T3N1H4 </span>
          </li>
          <li className="footer-info__main-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-phone" />
            </span>
            <span>
              <a href="tel:+15877187190 ">+1 587-718-7190 </a>
            </span>
          </li>
          <li className="footer-info__main-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-circle-info" />
            </span>
            <span>
              <a href="mailto:info@beaconservices.ca">
                info@beaconservices.ca{" "}
              </a>
            </span>
          </li>
          <li className="footer-info__main-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-envelope" />
            </span>
            <span>
              <a href="mailto:contactus@beaconservices.ca">
                contactus@beaconservices.ca{" "}
              </a>
            </span>
          </li>
        </ul>
        <ul className="footer-info__parent">
          <li className="footer-info__main-title">Ontario Branch</li>
          <li className="footer-info__parent-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
            </span>
            <span>
              170 Ambassador Dr, 6, Mississauga, Ontario, Canada, L5T2H9
            </span>
          </li>
          <li className="footer-info__parent-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-phone" />
            </span>
            <span>
              <a href="tel:+14377762833">+1 437-776-2833 </a>
            </span>
          </li>
          <li className="footer-info__parent-item">
            <span>
              <FontAwesomeIcon icon="fa-solid fa-circle-info" />
            </span>
            <span>
              <a href="mailto:info@nmtes.in">info@nmtes.in </a>
            </span>
          </li>
        </ul>
      </div>
      <div className="footer__text">
        Copyright{" "}
        <FontAwesomeIcon icon="fa-regular fa-copyright" color="#fff" /> 2023
        Beacon O&G Services
      </div>
    </footer>
  );
}

export default Footer;
