import React, { useEffect, useState } from "react";
import "./Cards.scss";
import Card from "./Card";
import { getServiceData } from "./../../data/services";

function Cards({ from }) {
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    getServiceData().then((res) => setServiceData(res));
  }, []);
  return (
    <>
      {from === "homepage" ? (
        <div className="homepage">
          {serviceData.map((data, i) => (
            <Card key={i} service={data} />
          ))}
        </div>
      ) : (
        <>
          {serviceData.map((data, i) => (
            <Card key={i} service={data} />
          ))}
        </>
      )}
    </>
  );
}

export default Cards;
